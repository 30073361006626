import {
  Drawer,
  Typography,
  Grid,
  Button,
  Container,
  Stack,
  Box,
} from '@mui/material';
import React from 'react';
import { selectArsenalBag } from 'app/pages/Arsenal/slice/selectors';
import BowlingBallCard from '../BowlingBallCard';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentBallSlice } from './slice';
import { APIBallData } from 'app/pages/Arsenal/slice/types';

interface Props {
  open: boolean;
  setOpen: Function;
}
export function BallSelection(props: Props) {
  const { open, setOpen } = props;
  const bag = useSelector(selectArsenalBag);
  const dispatch = useDispatch();
  const { actions } = useCurrentBallSlice();

  const BallSelectionAction = (ball: APIBallData) => (
    <Button
      variant="contained"
      onClick={() => {
        dispatch(actions.setCurrentBall(ball));
        setOpen(false);
      }}
    >
      Switch to this Ball
    </Button>
  );

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Button
        variant="contained"
        onClick={() => setOpen(false)}
        fullWidth={true}
        sx={{ position: 'fixed', zIndex: '100' }}
      >
        Close
      </Button>
      <Grid container>
        <Grid item xs={12} md={6} mt={10} />
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={1}>
            {bag && bag.length === 0 && (
              <Container>
                <Typography variant="h5">
                  Your bag is empty. Add a ball!
                </Typography>
              </Container>
            )}
            {bag &&
              bag.map(ball => (
                <Container>
                  <BowlingBallCard
                    {...ball}
                    actions={[<BallSelectionAction {...ball} />]}
                  />
                </Container>
              ))}
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
}
