import { Stack, Button } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  TextFieldElement,
  ToggleButtonGroupElement,
  useForm,
} from 'react-hook-form-mui';
import React from 'react';
import { APIBallData } from 'app/pages/Arsenal/slice/types';
import { useDispatch } from 'react-redux';
import { useArsenalSlice } from 'app/pages/Arsenal/slice';
type IProps = Pick<
  APIBallData,
  | 'id'
  | 'surface_grit'
  | 'surface_mode'
  | 'surface_grit2'
  | 'surface_grit3'
  | 'frames_since_last_surface'
  | 'frames_total'
>;
export function SurfaceForm(props: IProps) {
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      mode: props.surface_mode,
      grit1: props.surface_grit,
      grit2: props.surface_grit2,
      grit3: props.surface_grit3,
      gamesAtSurface: (props.frames_since_last_surface || 0) / 10,
      games: props.frames_total,
    },
  });

  const dispatch = useDispatch();
  const { actions } = useArsenalSlice();
  return (
    <form
      onSubmit={handleSubmit(data =>
        dispatch(
          actions.updateSurface({
            ...props,
            frames_since_last_surface: data.gamesAtSurface * 10,
            surface_mode: data.mode,
            surface_grit: data.grit1,
            surface_grit2: data.grit2,
            surface_grit3: data.grit3,
          }),
        ),
      )}
      noValidate
    >
      <Stack spacing={1} p={0}>
        <ToggleButtonGroupElement
          color="primary"
          name={'mode'}
          label={''}
          control={control}
          required
          fullWidth
          enforceAtLeastOneSelected
          exclusive
          options={[
            {
              id: 'Polished',
              label: 'Polished',
            },
            {
              id: 'Sanded',
              label: 'Sanded',
            },
          ]}
        />
        <TextFieldElement
          margin="none"
          label={'Grit 1'}
          name={'grit1'}
          type={'number'}
          control={control}
        />
        <TextFieldElement
          margin="none"
          label={'Grit 2'}
          name={'grit2'}
          type={'number'}
          control={control}
        />
        <TextFieldElement
          margin="none"
          label={'Grit 3'}
          name={'grit3'}
          type={'number'}
          control={control}
        />

        <Stack direction={'row'} spacing={2}>
          <TextFieldElement
            margin="dense"
            label={'Games @ Surface'}
            name={'gamesAtSurface'}
            type={'number'}
            control={control}
          />
          <Button
            variant="contained"
            onClick={() => setValue('gamesAtSurface', 0)}
            endIcon={<RestartAltIcon />}
            sx={{ p: 2 }}
          >
            Reset
          </Button>
        </Stack>
        <TextFieldElement
          margin="dense"
          label={'Total Games'}
          name={'games'}
          type={'number'}
          control={control}
        />
        <Button type={'submit'} color={'primary'}>
          Submit
        </Button>
      </Stack>
    </form>
  );
}
