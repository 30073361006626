/**
 *
 * Arsenal
 *
 */
import * as React from 'react';
import { useArsenalSlice } from './slice';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BowlingBallCard from 'app/components/BowlingBallCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectArsenalBag } from './slice/selectors';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddCircle, Padding } from '@mui/icons-material';

interface Props {}

export function Arsenal(props: Props) {
  const { actions } = useArsenalSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bag = useSelector(selectArsenalBag);
  React.useEffect(() => {
    dispatch(actions.listArsenalStart());
  }, [actions, dispatch, navigate]);

  return (
    <>
      <Helmet>
        <title>Arsenal</title>
        <meta name="description" content="Your bowling bag" />
      </Helmet>
      <Container>
        <Box textAlign="center" padding={1}>
          <Button
            onClick={() => navigate('search', {})}
            variant="contained"
            sx={{ margin: 1 }}
            endIcon={<AddCircleIcon />}
          >
            Add ball to bag
          </Button>
        </Box>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={1}>
              {bag && bag.length === 0 && (
                <Container>
                  <Typography variant="h5">
                    Your bag is empty. Add a ball!
                  </Typography>
                </Container>
              )}
              {bag &&
                bag.map(ball => (
                  <Container>
                    <BowlingBallCard {...ball} />
                  </Container>
                ))}
            </Stack>
          </Grid>
          <Grid item md={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Stack direction="column">Overflow</Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
