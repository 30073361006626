/**
 *
 * PatternViewer
 *
 */

import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Lane from 'app/components/lane/Lane';
import { Logo } from 'app/components/Logo';
import { useLaneSlice } from 'app/components/lane/Lane/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLane } from 'app/components/lane/Lane/slice/selectors';
import BasicModal from 'app/components/lane/Model';
import { useState } from 'react';
import { laneActions } from 'app/components/lane/Lane/slice';
import TransitionIcon from '@mui/icons-material/East';
import {
  LANE_TOTAL_LENGTH,
  LANE_WIDTH,
} from 'app/components/lane/Lane/constants';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useSearchParams } from 'react-router-dom';
import { Share, Clear, HighlightOff, Article } from '@mui/icons-material';
import { useWindowSize } from 'usehooks-ts';
import {
  targetListActions,
  useTargetListSlice,
} from 'app/components/TargetList/slice';
import { selectTargetList } from 'app/components/TargetList/slice/selectors';
import PatternSelectorDrawer from 'app/components/lane/Pattern/PatternSelectorDrawer';
import { isMobile } from 'react-device-detect';
import { ExternalLink } from 'utils/nav';
import { AppActionsContext } from 'app/components/ResponsiveAppBar/AppActionsContext';
import { notificationsActions } from 'app/components/Notifications/slice';
import { CopyLink } from 'app/components/CopyLink';
interface Props {}

export function PatternViewer(props: Props) {
  useTargetListSlice();
  useLaneSlice();
  const dispatch = useDispatch();
  const [isInitalized, setIsInitialized] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveTargetLabel, setSaveTargetLabel] = React.useState('');

  const lane = useSelector(selectLane);
  const [openSetLaydown, setOpenSetLaydown] = React.useState(false);
  const [openSetTarget, setOpenSetTarget] = React.useState(false);
  const [openSetExitpoint, setOpenSetExitpoint] = React.useState(false);
  const [proposedNewTarget, setProposedNewMarker] = React.useState({
    board: -1,
    distance: -1,
  });

  const { height } = useWindowSize();
  const { appActions, setAppActions } = React.useContext(AppActionsContext);

  React.useEffect(() => {
    if (!isInitalized && searchParams.get('tb') !== null) {
      dispatch(laneActions.setLine(searchParams.toString()));
    }
    setSearchParams(new URLSearchParams(lane.encodedLine), { replace: true });

    setIsInitialized(true);

    const Reset = () => (
      <Button
        sx={{ zIndex: 100, fontSize: '.7em' }}
        fullWidth={true}
        variant="contained"
        size="small"
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          dispatch(laneActions.resetTarget());
        }}
        endIcon={<HighlightOff fontSize="small" />}
      >
        Reset
      </Button>
    );
    setAppActions([
      <Box key="actions" flex={1} sx={{ px: 2 }}>
        <Stack direction="row" spacing={2}>
          <CopyLink />
          <Reset />
        </Stack>
      </Box>,
    ]);
  }, [
    searchParams,
    setSearchParams,
    lane,
    dispatch,
    isInitalized,
    height,
    setAppActions,
  ]);

  const heightScaleFactor =
    (height - 150 - (isMobile ? 0 : 45)) / LANE_TOTAL_LENGTH;
  return (
    <>
      <Helmet>
        <title>Target Explorer</title>
        <meta name="description" content="Improve your bowling" />
      </Helmet>

      <PatternSelectorDrawer />
      {/* Break the screen up into content and large screen overflow */}
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={3}>
          <Grid container border={0} margin={0} padding={0}>
            <Grid
              item
              xs={6}
              zIndex={100}
              margin={0}
              padding={0}
              sx={{ zIndex: 2 }}
            >
              <Box>
                <svg
                  height="100vh"
                  overflow="visible"
                  // width={'100%'}
                  // width="100vw"
                  // viewBox={`0 ${window.innerWidth / lane.yScaleFactor} ${
                  //   window.innerHeight / 4
                  // }`}
                  // viewBox={`0 700 600 600`}
                  preserveAspectRatio="xMaxYMin meet"
                >
                  <g
                    transform={`scale(${
                      3 * heightScaleFactor
                    },${heightScaleFactor})`}
                  >
                    <Lane
                      selector={lane}
                      showMarkerLabels={true}
                      setOpenSetLaydown={() => setOpenSetLaydown(true)}
                      setOpenSetTarget={() => setOpenSetTarget(true)}
                      setOpenSetExitpoint={() => setOpenSetExitpoint(true)}
                    ></Lane>
                  </g>
                </svg>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={12} margin={1}>
                  <Stack spacing={1}>
                    <Divider />
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography variant="body1">Save Target</Typography>
                      <Button
                        sx={{ textTransform: 'none' }}
                        href="/target/saved"
                      >
                        See my list
                      </Button>
                    </Stack>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        if (saveTargetLabel !== '') {
                          dispatch(
                            targetListActions.saveTargetLink({
                              label: saveTargetLabel,
                              link: document.location.toString(),
                            }),
                          );
                          setSaveTargetLabel('');
                          dispatch(
                            notificationsActions.toast({
                              component: `Saved target to '${saveTargetLabel}'`,
                              options: { type: 'success' },
                            }),
                          );
                        }
                      }}
                    >
                      <TextField
                        sx={{ zIndex: 101 }}
                        fullWidth
                        margin="none"
                        value={saveTargetLabel}
                        size="small"
                        placeholder="Enter name to Save"
                        onChange={event =>
                          setSaveTargetLabel(event.target.value)
                        }
                      />
                    </form>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* overflow column */}
          <Grid />
          <BasicModal open={openSetLaydown} setOpen={setOpenSetLaydown}>
            <>
              <Typography variant="h2">Laydown</Typography>
              <Typography variant="body2">
                Zoom/Pan to select your new laydown board
              </Typography>
              <Grid container padding={0} margin={0}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  m={12}
                  border={1}
                  margin={0}
                  padding={0}
                >
                  <TransformWrapper
                    // centerOnInit={true}
                    initialPositionX={0}
                    initialPositionY={-(LANE_TOTAL_LENGTH - 30) * 5}
                    // // centerOnInit={true}
                    initialScale={5}
                    maxScale={20}
                    // limitToBounds={false}
                    // onInit={ref => {
                    //   ref.centerView(5);
                    //   ref.zoomToElement('MarkerOverlay', 8);
                    // }}
                    // centerZoomedOut={true}
                  >
                    <TransformComponent
                      wrapperStyle={{ width: '100%', height: '40vh' }}
                    >
                      <svg
                        height={LANE_TOTAL_LENGTH}
                        width={LANE_WIDTH}
                        // width={window.innerWidth}
                        // width={window.innerWidth}
                        // width="100vh"
                        // viewBox={`0 500 ${window.innerWidth / lane.yScaleFactor} ${
                        //   window.innerHeight / 4
                        // }`}
                        // viewBox={`0 700 600 600`}
                        // preserveAspectRatio="xMinYMin meet"
                      >
                        {/* <g
                  transform={`scale(${
                    1 * heightScaleFactor
                  },${heightScaleFactor})`}
                > */}
                        <Lane
                          selector={lane}
                          showMarkerLabels={false}
                          onBoardClick={(board, distance) => {
                            console.log(`${board} @ ${distance}`);
                            setProposedNewMarker({ board, distance });
                          }}
                        ></Lane>
                        {/* </g> */}
                      </svg>
                    </TransformComponent>
                  </TransformWrapper>
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign={'center'} padding={2}>
                    <Button
                      color="warning"
                      onClick={() => {
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetLaydown(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                {proposedNewTarget.board !== -1 && (
                  <>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="h3" paddingX={4}>
                          {lane.laydownBoard.toFixed(1)}
                        </Typography>
                        <TransitionIcon style={{ transform: `scale(2)` }} />
                        <Typography variant="h3" paddingX={4}>
                          {proposedNewTarget.board.toFixed(1)}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyItems="center"
                        justifyContent="center"
                        spacing={3}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              laneActions.updateLaydownCalculateTarget(
                                proposedNewTarget.board,
                              ),
                            );
                            setProposedNewMarker({ board: -1, distance: -1 });
                            setOpenSetLaydown(false);
                          }}
                        >
                          Update Target
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              laneActions.updateLaydownCalculateExit(
                                proposedNewTarget.board,
                              ),
                            );
                            setProposedNewMarker({ board: -1, distance: -1 });
                            setOpenSetLaydown(false);
                          }}
                        >
                          Update Exit
                        </Button>
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          </BasicModal>
          <BasicModal open={openSetTarget} setOpen={setOpenSetTarget}>
            <>
              <Typography variant="h2">Target</Typography>
              <Typography variant="body2">
                Zoom/Pan to select your new target board
              </Typography>
              <Grid container padding={0} margin={0}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  m={12}
                  border={1}
                  margin={0}
                  padding={0}
                >
                  <TransformWrapper
                    initialPositionX={0}
                    initialPositionY={
                      -(LANE_TOTAL_LENGTH - lane.targetDistance * 12 - 30) * 5
                    }
                    initialScale={5}
                    maxScale={20}
                  >
                    <TransformComponent
                      wrapperStyle={{ width: '100%', height: '40vh' }}
                    >
                      <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                        <Lane
                          selector={lane}
                          showMarkerLabels={false}
                          onBoardClick={(board, distance) => {
                            console.log(`${board} @ ${distance}`);
                            setProposedNewMarker({ board, distance });
                          }}
                        ></Lane>
                      </svg>
                    </TransformComponent>
                  </TransformWrapper>
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign={'center'} padding={2}>
                    <Button
                      color="warning"
                      onClick={() => {
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetTarget(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                {proposedNewTarget.board !== -1 && (
                  <>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="h3" paddingX={4}>
                          {lane.targetBoard.toFixed(1)}
                        </Typography>
                        <TransitionIcon style={{ transform: `scale(3)` }} />
                        <Typography variant="h3" paddingX={4}>
                          {proposedNewTarget.board.toFixed(1)}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyItems="center"
                        justifyContent="center"
                        spacing={3}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              laneActions.updateTargetCalculateLaydown({
                                ...proposedNewTarget,
                              }),
                            );
                            setProposedNewMarker({ board: -1, distance: -1 });
                            setOpenSetTarget(false);
                          }}
                        >
                          Update Laydown
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              laneActions.updateTargetCalculateExit({
                                ...proposedNewTarget,
                              }),
                            );
                            setProposedNewMarker({ board: -1, distance: -1 });
                            setOpenSetTarget(false);
                          }}
                        >
                          Update Exit
                        </Button>
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          </BasicModal>
          <BasicModal open={openSetExitpoint} setOpen={setOpenSetExitpoint}>
            <>
              <Typography variant="h2">Pattern Exit</Typography>
              <Typography variant="body2">
                Zoom/Pan to select your new exit board
              </Typography>
              <Grid container padding={0} margin={0}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  m={12}
                  border={1}
                  margin={0}
                  padding={0}
                >
                  <TransformWrapper
                    initialPositionX={0}
                    initialPositionY={
                      -(LANE_TOTAL_LENGTH - lane.exitDistance * 12 - 30) * 5
                    }
                    initialScale={5}
                    maxScale={20}
                  >
                    <TransformComponent
                      wrapperStyle={{ width: '100%', height: '40vh' }}
                    >
                      <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                        <Lane
                          selector={lane}
                          showMarkerLabels={false}
                          onBoardClick={(board, distance) => {
                            console.log(`${board} @ ${distance}`);
                            setProposedNewMarker({ board, distance });
                          }}
                        ></Lane>
                      </svg>
                    </TransformComponent>
                  </TransformWrapper>
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign={'center'} padding={2}>
                    <Button
                      color="warning"
                      onClick={() => {
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetExitpoint(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                {proposedNewTarget.board !== -1 && (
                  <>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="h3" paddingX={4}>
                          {lane.exitBoard.toFixed(1)}
                        </Typography>
                        <TransitionIcon style={{ transform: `scale(3)` }} />
                        <Typography variant="h3" paddingX={4}>
                          {proposedNewTarget.board.toFixed(1)}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyItems="center"
                        justifyContent="center"
                        spacing={3}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              laneActions.updateExitCalculateLaydown({
                                ...proposedNewTarget,
                              }),
                            );
                            setProposedNewMarker({ board: -1, distance: -1 });
                            setOpenSetExitpoint(false);
                          }}
                        >
                          Update Laydown
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              laneActions.updateExitCalculateTarget({
                                ...proposedNewTarget,
                              }),
                            );
                            setProposedNewMarker({ board: -1, distance: -1 });
                            setOpenSetExitpoint(false);
                          }}
                        >
                          Update Target
                        </Button>
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          </BasicModal>
        </Grid>
        <Grid item lg={4} md={3} xs={0} marginBottom={60}>
          <Paper sx={{ padding: 2, height: '100%', width: '100%' }}>
            <Box>
              <Typography variant="h3">About</Typography>
              <Typography>
                This is based on{' '}
                <Link
                  component={ExternalLink}
                  href="https://www.kegeltrainingcenter.com/ktc-magazine/2014/6/7/understanding-3-point-targeting-with-quiet-eye-in-7-easy-steps"
                  target="_blank"
                >
                  the 3-Point Targeting
                </Link>{' '}
                system.
              </Typography>
              <Divider style={{ margin: 10 }} />
              <Typography>
                It's designed to help you understand your ball motion, align
                your armswing and pick the right area to play on the lane for a
                particular oil pattern.
              </Typography>
              <Divider style={{ margin: 5 }} />
              <Typography variant="subtitle1" sx={{ color: '#777' }}>
                Focal Point
              </Typography>
              <Typography>
                This is the top of the green line near the pins. It helps you
                align your armswing to your intended line.
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'red' }}>
                Exit Point
              </Typography>
              <Typography>
                The point that the ball will see the friction and transition to
                the hook phase. This is <em>not </em> your breakpoint; your
                breakpoint will be influenced by your ball selection, surface
                and drill layout.
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'green' }}>
                Target
              </Typography>
              <Typography>
                Where you're looking as you release the ball. Typically, it's
                near the arrows, but can be anywhere between the foul line and
                your exit point
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'blue' }}>
                Laydown
              </Typography>
              <Typography>
                This is the point at which the ball first touches the lane. This
                is <em>not</em> where you stand. That's unique to every bowler
                and will depend on what part of your foot you use, your drift
                and how close to your ankle you release the ball.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
