/**
 *
 * SurfaceManager
 *
 */
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import * as React from 'react';
import BowlingBallCard from '../BowlingBallCard';
import { useArsenalSlice } from 'app/pages/Arsenal/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectBallById } from 'app/pages/Arsenal/slice/selectors';
import { SurfaceForm } from './SurfaceForm';

interface Props {}

export function SurfaceManager(props: Props) {
  const { actions } = useArsenalSlice();
  const { id } = useParams();
  const ball = useSelector(selectBallById(id || ''));
  const navigate = useNavigate();
  return (
    <>
      <Paper variant="outlined" sx={{ margin: 0 }}>
        <Grid padding={1} container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={6} lg={6} xl={5}>
            <Grid item xs={12}>
              <Typography variant="h4" mt={3}>
                Manage Surface
              </Typography>
              <Typography mb={2}>
                <Button onClick={() => navigate(-1)}>
                  <Typography variant="body1" padding={2} paddingBottom={0}>
                    Back
                  </Typography>
                </Button>
                {/* <Link variant="inherit" href="..">
                  Back to Arsenal
                </Link> */}
              </Typography>
            </Grid>
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={8}>
                <BowlingBallCard noActions {...(ball as any)} />
              </Grid>
              <Grid item xs={12} mt={5} mb={10}>
                <SurfaceForm {...(ball as any)} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={6} lg={6} xl={7}>
            <Grid xs={12}>Overflow</Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
