import { bowlingSetActions } from 'app/components/BowlingSet/slice';
import { selectBowlingSet } from 'app/components/BowlingSet/slice/selectors';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectGameManager } from 'app/components/GameManager/slice/selectors';
import { notificationsActions } from 'app/components/Notifications/slice';
import { delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { sessionCacheActions as actions } from '.';

// function* doSomething() {}

export function* sessionCacheSaga() {
  yield fork(cacheRestore);
  yield takeLatest(
    [
      gameManagerActions.newFrame,
      gameManagerActions.commit,
      bowlingSetActions.start,
    ],
    cacheWatcher,
  );
  yield takeLatest(
    [
      gameManagerActions.completeSet,
      bowlingSetActions.complete,
      bowlingSetActions.abandon,
    ],
    cleanCache,
  );
}

export function* cacheWatcher() {
  const game = yield select(selectGameManager);
  const set = yield select(selectBowlingSet);
  localStorage.setItem('GAME_STATUS', JSON.stringify(game));
  localStorage.setItem('SET_STATUS', JSON.stringify(set));
}

export function* cleanCache() {
  localStorage.removeItem('GAME_STATUS');
  localStorage.removeItem('SET_STATUS');
  yield;
}

export function* cacheRestore() {
  const currentGameStr = localStorage.getItem('GAME_STATUS');
  const currentSetStr = localStorage.getItem('SET_STATUS');
  try {
    if (currentSetStr !== null && currentGameStr !== null) {
      const currentSet = JSON.parse(currentSetStr);
      const currentGame = JSON.parse(currentGameStr);
      yield delay(3000);
      yield put(bowlingSetActions.restoreSet({ state: currentSet }));
      yield put(
        gameManagerActions.restoreGame({
          state: currentGame,
          lastDelivery:
            currentGame.deliveries[currentGame.deliveries.length - 1],
        }),
      );
      yield put(
        notificationsActions.toast({ component: 'Restored game in progress' }),
      );
    }
  } catch (e) {
    yield put(
      notificationsActions.toast({
        component: 'DBUG: failure in Restore game',
        options: { type: 'error' },
      }),
    );
    yield put(
      notificationsActions.toast({
        component: 'DBUG: error: ' + (e as string).toString(),
        options: { type: 'error' },
      }),
    );
    cleanCache();
  }
  yield put(actions.initialized());
}
