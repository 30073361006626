/**
 *
 * CharterPage
 *
 */
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { PinFallController } from 'app/components/PinFallController';
import {
  LANE_WIDTH,
  PIN_DECK_LENGTH,
} from 'app/components/lane/Lane/constants';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { usePinFallControllerSlice } from 'app/components/PinFallController/slice';
import { BallDeckExitPoint } from 'app/components/BallDeckExitPoint';
import { DeliveryController } from 'app/components/DeliveryController';
import { GameManager } from 'app/components/GameManager';
import { LaneTargets } from 'app/components/LaneTargets';
import { PocketEntry } from 'app/components/PocketEntry';
import { ScoreLine } from 'app/components/ScoreLine';
import { CurrentBall } from 'app/components/CurrentBall';
import { TargetExecution } from 'app/components/TargetExecution';
import { FrameNotes } from 'app/components/FrameNotes';
import {
  useTargetLeftSlice,
  useTargetRightSlice,
} from 'app/components/lane/Lane/slice';
import { useGameManagerSlice } from 'app/components/GameManager/slice';
import { SpeedControl } from 'app/components/SpeedControl';
import { LoftControl } from 'app/components/LoftControl';
import { BowlingSet } from 'app/components/BowlingSet';
import { useSessionCacheSlice } from 'app/components/SessionCache/slice';
import { selectSessionCache } from 'app/components/SessionCache/slice/selectors';
import { useBowlingSetSlice } from 'app/components/BowlingSet/slice';
import { useBallDeckExitPointSlice } from 'app/components/BallDeckExitPoint/slice';
import { useCurrentBallSlice } from 'app/components/CurrentBall/slice';
import { useNotificationsSlice } from 'app/components/Notifications/slice';
import { useFrameNotesSlice } from 'app/components/FrameNotes/slice';
import { useTargetExecutionSlice } from 'app/components/TargetExecution/slice';
import { useLoftControlSlice } from 'app/components/LoftControl/slice';
import { usePocketEntrySlice } from 'app/components/PocketEntry/slice';
import { useArsenalSlice } from '../Arsenal/slice';

interface Props {}

export function CharterPage(props: Props) {
  useGameManagerSlice();
  useBowlingSetSlice();
  useSessionCacheSlice();
  useBallDeckExitPointSlice();
  useCurrentBallSlice();
  usePinFallControllerSlice();
  useTargetLeftSlice();
  useTargetRightSlice();
  useNotificationsSlice();
  useFrameNotesSlice();
  useTargetExecutionSlice();
  useLoftControlSlice();
  usePocketEntrySlice();
  useArsenalSlice();

  // if (localStorage.getItem('GAME_STATUS') !== null) {
  //   return <p>Game in progress</p>;
  // }
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  const sessionCache = useSelector(selectSessionCache);
  if (sessionCache.loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Box
          m={5}
          display="flex"
          // width={{ sm: '90%', md: '50%' }}
          alignContent="center"
          // justifyContent="center"
          justifyItems="center"
          // justifySelf="center"
        >
          <CircularProgress />
        </Box>
      </Grid>
    );
  }

  return (
    <>
      <BowlingSet>
        <GameManager />
        <Grid container padding={1} margin={0} spacing={0}>
          {/* Left app */}
          <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
            <Grid container>
              <Grid item xs={9}>
                <Box component={Grid} item xs={12}>
                  <Stack>
                    <BallDeckExitPoint />
                    <svg
                      height="35vh"
                      viewBox={`0 0 ${LANE_WIDTH} ${PIN_DECK_LENGTH + 5}`}
                      // preserveAspectRatio="none"
                      preserveAspectRatio="xMidYMin meet"
                      // transform="scale(.75,.75)"
                      pointerEvents="none"
                    >
                      <PinFallController />
                    </svg>
                    <PocketEntry />
                    <TargetExecution />
                    <DeliveryController />
                    <Grid container>
                      <Container>
                        <Grid item xs={12}>
                          <LaneTargets />
                        </Grid>
                      </Container>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <CurrentBall />
                  <FrameNotes />
                  <SpeedControl />
                  <LoftControl />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                    <ScoreLine />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={9} xl={10}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  display={{
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block',
                    xl: 'block',
                  }}
                >
                  <Typography variant="h2">overflow 1</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BowlingSet>
    </>
  );
}
