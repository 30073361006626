import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { arsenalSaga } from './saga';
import { APIBallData, ArsenalState } from './types';
import { SagaInjectionModes } from 'redux-injectors';

export const initialState: ArsenalState = {
  ballSearchResults: [],
  bag: [],
  fetching: false,
  search: '',
};

const slice = createSlice({
  name: 'arsenal',
  initialState,
  reducers: {
    ballSearchStart(state, action: PayloadAction<{ search: string }>) {
      state.fetching = true;
    },
    ballSearchError(state, action: PayloadAction<{ error: string }>) {},
    ballSearchSuccess(state, action: PayloadAction<any>) {
      state.ballSearchResults = action.payload;
    },
    deleteBall(state, action: PayloadAction<{ id: string }>) {
      state.bag = state.bag.filter(ball => ball.id !== action.payload.id);
    },
    deleteBallSuccess(
      state,
      action: PayloadAction<{ result: string; ball: APIBallData }>,
    ) {},
    deleteBallFail(state, action: PayloadAction<{ error: string }>) {},
    activateBall(state, action: PayloadAction<{ id: string }>) {},
    activateBallSuccess(
      state,
      action: PayloadAction<{ result: string; ball: APIBallData }>,
    ) {},
    activateBallFail(state, action: PayloadAction<{ error: string }>) {},
    addBallToBag(
      state,
      action: PayloadAction<{
        navigate: any;
        ballId: string;
        ballName: string;
        layout: string;
        weight: string;
        surfaceMode: string;
        framesAtSurface: number;
        totalFrames: number;
        grit: number;
      }>,
    ) {},
    listArsenalStart(state) {
      state.fetching = true;
    },
    listArsenalSuccess(state, action: PayloadAction<APIBallData[]>) {
      state.fetching = false;
      state.bag = action.payload;
    },
    listArsenalFail(state, action) {
      state.fetching = false;
    },
    updateSurface(
      state,
      action: PayloadAction<
        Pick<
          APIBallData,
          | 'id'
          | 'surface_grit'
          | 'surface_mode'
          | 'surface_grit2'
          | 'surface_grit3'
          | 'frames_since_last_surface'
          | 'frames_total'
        >
      >,
    ) {
      state.fetching = false;
    },
    updateSurfaceSuccess(
      state,
      action: PayloadAction<{ result: string; ball: APIBallData }>,
    ) {},
    updateSurfaceFail(state, action: PayloadAction<{ error: string }>) {},
  },
});

export const { actions: arsenalActions } = slice;

export const useArsenalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({
    key: slice.name,
    saga: arsenalSaga,
    mode: SagaInjectionModes.RESTART_ON_REMOUNT,
  });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useArsenalSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
