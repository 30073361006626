import { Box } from '@mui/material';
import * as React from 'react';

interface Props {
  title: string;
  width?: string;
}

export function BallImage(props: Props) {
  const { title, width } = props;
  return (
    <Box
      component="img"
      title={title}
      sx={{
        padding: '3px',
        width: width ? width : '100%',
      }}
      src={`/images/unknown_ball.png`}
      alt={title}
    />
  );
}
