import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { CurrentBallState } from './types';

import _1 from '../inventory/motive_pride_emerald.json';
import _2 from '../inventory/radical+innovator_solid.json';
import _3 from '../inventory/brunswick_defender.json';
import _4 from '../inventory/hammer_3doffset.json';
import _5 from '../inventory/storm_promotion.json';
import _6 from '../inventory/hammer_scandals.json';
import _7 from '../inventory/global900_zen.json';
import _8 from '../inventory/storm_hyroad-pearl.json';
import _9 from '../inventory/roto-grip-hyped-hybrid.json';
import _10 from '../inventory/hammer-blue-nu.json';
import _11 from '../inventory/global900_zen_soul.json';
import _12 from '../inventory/ebionite_gb4.json';
import _13 from '../inventory/hammer_psycho.json';
import _14 from '../inventory/hammer-bw.json';
import _15 from '../inventory/brunswick_rhino-matallic.json';
import _16 from '../inventory/storm_electrify-bsy.json';
import _17 from '../inventory/radical_sneak_attack_peral.json';

import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectCurrentBall, selectCurrentBallReport } from './selectors';
import {
  GameManagerState,
  AvailableCollectors,
} from 'app/components/GameManager/slice/types';
import { APIBallData } from 'app/pages/Arsenal/slice/types';
export const initialState: CurrentBallState = {};

const slice = createSlice({
  name: 'currentBall',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        gameManagerActions.commit,
        (state, action: PayloadAction<any>) => {
          state.current?.frames_since_last_surface &&
            state.current.frames_since_last_surface++;
        },
      )
      .addCase(
        gameManagerActions.beginEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.save = state.current;
        },
      )
      // .addCase(
      //   gameManagerActions.setEditDelivery,
      //   (
      //     state,
      //     action: PayloadAction<{
      //       ball?: ReturnType<typeof selectCurrentBallReport>;
      //     }>,
      //   ) => {
      //     state.current = state.inventory.find(
      //       ball => ball.id === action.payload.ball?.id,
      //     ) as any;
      //   },
      // )
      .addCase(
        gameManagerActions.finishEditDelivery,
        (state, action: PayloadAction<any>) => {
          if (state.save) {
            state.current = state.save;
          }
        },
      );
  },
  reducers: {
    setCurrentBall(state, action: PayloadAction<APIBallData>) {
      state.current = action.payload;
      // state.inventory.find(ball => ball.id === action.payload.id) ||
      // state.current;
    },
  },
});

export const { actions: currentBallActions } = slice;

export const useCurrentBallSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCurrentBallSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
