import {
  take,
  put,
  takeLeading,
  fork,
  delay,
  race,
  call,
  retry,
  takeLatest,
} from 'redux-saga/effects';
import { ssoActions as actions } from '.';
import extractIdentity from './extractOAuthTokens';
import { AwsRumConfig, AwsRum } from 'aws-rum-web';
import reportWebVitals from 'reportWebVitals';
import { toast } from 'react-toastify';
import { notificationsActions } from 'app/components/Notifications/slice';
import { v4 } from 'uuid';
const usedTokens = {};
let awsRum;

// startRumForGuestUsers();

export function* ssoSaga() {
  yield fork(refreshToken);
  yield takeLeading(actions.auth.type, authenticate);
  yield takeLatest(actions.identify.type, startRumForAuthedUsers);
  yield takeLatest(actions.unAuth.type, startRumForGuestUsers);
}

function* authenticate(action) {
  console.log(action.payload);

  if (usedTokens[action.payload.codeGrant]) {
    console.log('Token already used');
    return;
  }

  usedTokens[action.payload.codeGrant] = true;

  const requestSearchParams = new URLSearchParams();
  requestSearchParams.set('code', action.payload.codeGrant);
  requestSearchParams.set('grant_type', 'authorization_code');
  requestSearchParams.set('redirect_uri', `${process.env.REACT_APP_URI}sso/`);
  requestSearchParams.set(
    'client_id',
    process.env.REACT_APP_CLIENT_ID as string,
  );

  const response = yield fetch(buildCognitoDomainUri(), {
    method: 'POST',
    body: requestSearchParams,
  });

  if (response.status !== 200) {
    console.warn('Could not auth');
    return;
  }
  const oauthTokens: any = yield response.json();
  localStorage.setItem(
    'AWS_TEMP_CRED_REFRESH_TOKEN',
    oauthTokens.refresh_token,
  );
  localStorage.setItem(
    'AWS_TEMP_CRED_REFRESH_TOKEN_TIME',
    new Date().valueOf().toString(),
  );
  localStorage.setItem('AWS_ID_TOKEN', oauthTokens.id_token);
  const { username, name } = extractIdentity(oauthTokens.id_token);
  yield put(
    actions.identify({
      identity: oauthTokens.id_token as string,
      username,
      name,
    }),
  );
}

export function* refreshToken() {
  // restore session
  yield fork(debugRefreshToken);
  while (true) {
    try {
      const identityToken = localStorage.getItem('AWS_ID_TOKEN');
      const { username, name } = extractIdentity(identityToken);
      if (identityToken) {
        yield put(
          actions.identify({
            identity: identityToken,
            username,
            name,
          }),
        );
        startRumForAuthedUsers();
      }
    } catch (err) {
      yield put(
        notificationsActions.toast({
          component: (err as any).toString(),
        }),
      );
      clearAuthInfo();
      console.warn('could not restore user session');
    }
    while (true) {
      const toastRetryId = v4();
      try {
        const refreshToken = localStorage.getItem(
          'AWS_TEMP_CRED_REFRESH_TOKEN',
        );
        if (refreshToken !== null && refreshToken !== undefined) {
          const requestSearchParams2 = new URLSearchParams();
          requestSearchParams2.set('refresh_token', refreshToken);
          requestSearchParams2.set('grant_type', 'refresh_token');
          requestSearchParams2.set(
            'client_id',
            process.env.REACT_APP_CLIENT_ID as string,
          );
          let shownError = false;
          const response2 = yield retry(30, 1000, async () => {
            try {
              const response = await fetch(buildCognitoDomainUri(), {
                method: 'POST',
                body: requestSearchParams2,
              });
              if (shownError) {
                toast.success('Service restored and authed', {
                  toastId: toastRetryId,
                });
              }
              return response;
            } catch (e) {
              if (!shownError) {
                toast.error('Failed to auth, retrying...', {
                  toastId: toastRetryId,
                });
                console.log(
                  'FIXME: factor this shit out to generator, use Api notification pattern, etc',
                );
              }
              shownError = true;
              throw e;
            }
          });
          if (response2.status === 400) {
            yield put(
              notificationsActions.toast({
                component: `You are not logged in`,
                options: {
                  type: 'warning',
                  autoClose: 30000,
                },
              }),
            );
            yield put(actions.unAuth());
          } else {
            const oauthTokens2 = yield response2.json();
            console.log(JSON.stringify(oauthTokens2));
            localStorage.setItem('AWS_BEARER_TOKEN', oauthTokens2.id_token);
            yield put(
              notificationsActions.toast({
                component: `DEBUG: is authed`,
                options: {
                  type: 'success',
                  autoClose: 500,
                },
              }),
            );
          }
        } else {
          yield put(actions.unAuth());
          yield put(
            notificationsActions.toast({
              component: 'DEBUG: NOT authed',
              options: {
                type: 'success',
                autoClose: 1,
                style: { visibility: 'hidden' },
              },
            }),
          );
        }
        yield put(actions.doneLoading());
        yield race({ delay: delay(180000), identify: take(actions.identify) });
      } catch (err) {
        console.warn(err);
        yield put(
          notificationsActions.toast({
            component: 'DEBUG: Error - main auth loop - retrying',
            options: {
              type: 'error',
              autoClose: 30000,
            },
          }),
        );
        clearAuthInfo();
        yield put(actions.unAuth());
        yield delay(2000);
      }
      break; // start main loop all over again
    }
  }
}

export function buildCognitoDomainUri() {
  return `${process.env.REACT_APP_COGNITO_DOMAIN}oauth2/token`;
}

export function clearAuthInfo(andId: boolean = false) {
  if (andId) {
    localStorage.removeItem('AWS_TEMP_CRED_REFRESH_TOKEN');
    localStorage.removeItem('AWS_ID_TOKEN');
  }
  localStorage.removeItem('AWS_BEARER_TOKEN');
}

function* debugRefreshToken() {
  yield delay(1000);
  const token = localStorage.getItem('AWS_TEMP_CRED_REFRESH_TOKEN');
  if (!token) {
    return;
  }
  const refreshDate =
    parseInt(
      localStorage.getItem('AWS_TEMP_CRED_REFRESH_TOKEN_TIME') as string,
    ) || 0;
  yield put(
    notificationsActions.toast({
      component: `DEBUG: refresh token time: ${new Date(refreshDate)}`,
      options: {
        type: 'info',
        autoClose: 500,
      },
    }),
  );
}

function startRumForGuestUsers() {
  try {
    // if (awsRum && awsRum.disable) {
    //   awsRum.disable();
    // }
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      // guestRoleArn:
      //   'arn:aws:iam::508964028585:role/RUM-Monitor-us-east-1-508964028585-3527519710471-Unauth',
      identityPoolId: 'us-east-1:ff1a9a08-b783-4fb4-b635-fbb25f42056a',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
      enableRumClient: true,
    };

    const APPLICATION_ID: string = '92d9ea79-5ba8-4625-b2be-cb8e1feb07ce';
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
    console.log('created awsRum');
    awsRum.enable();
  } catch (error) {
    console.log(error);
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

function startRumForAuthedUsers() {
  try {
    // if (awsRum && awsRum.disable) {
    //   awsRum.disable();
    // }
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      // guestRoleArn:
      //   'arn:aws:iam::508964028585:role/beta-Cognito_Unauth_Role-east',
      identityPoolId: 'us-east-1:b57d439a-5349-4c40-864f-96bf3376590c',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
      enableRumClient: true,
    };

    const APPLICATION_ID: string = 'fc9d6965-bd8e-480b-9f6e-c180d9229726';
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
    console.log('created awsRum');
  } catch (error) {
    console.log(error);
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals(console.log);
}
