import { arsenalActions as actions } from '.';
import {
  call,
  put,
  debounce,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { searchBalls } from 'api/ballSearch';
import { listArsenal, patchBall, postArsenal, putSurface } from 'api/arsenal';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectArsenalBag } from './selectors';
import { currentBallActions } from 'app/components/CurrentBall/slice';

export function* arsenalSaga() {
  yield takeLatest(actions.listArsenalStart.type, listBalls);
  yield debounce(500, actions.ballSearchStart.type, ballSearch);
  yield takeLatest(actions.addBallToBag.type, addBall);
  yield takeEvery(actions.deleteBall.type, deleteBall);
  yield takeEvery(actions.activateBall.type, activateBall);
  yield takeLatest(actions.updateSurface.type, updateSurface);
  yield takeLatest(gameManagerActions.restoreGame.type, setCurrentBall);
  yield call(listBalls);
}

export function* ballSearch(
  action: ReturnType<typeof actions.ballSearchStart>,
) {
  try {
    if (!action.payload.search) {
      return;
    }
    const response = yield call(searchBalls, action.payload.search);
    yield put(actions.ballSearchSuccess(response));
    console.log(response);
  } catch (error: any) {
    yield put(actions.ballSearchError(error.toString()));
  }
}

export function* addBall(action: ReturnType<typeof actions.addBallToBag>) {
  const b = action.payload;
  try {
    const response = yield call(
      postArsenal,
      b.ballId,
      parseInt(b.weight),
      b.surfaceMode,
      b.framesAtSurface,
      b.totalFrames,
      b.grit,
      b.layout,
    );

    yield action.payload.navigate(-1);
    // yield put(actions.ballSearchSuccess(response));
    console.log(response);
  } catch (error: any) {
    yield put(actions.ballSearchError(error.toString()));
  }
}

export function* deleteBall(action: ReturnType<typeof actions.deleteBall>) {
  const b = action.payload;
  try {
    const response = yield call(patchBall, b.id, 'deleted');

    yield put(actions.deleteBallSuccess(response));
  } catch (error: any) {
    yield put(actions.deleteBallFail(error.toString()));
  }
}

export function* activateBall(action: ReturnType<typeof actions.activateBall>) {
  const b = action.payload;
  try {
    const response = yield call(patchBall, b.id, 'active');
    yield put(actions.activateBallSuccess(response));
    yield put(actions.listArsenalStart());
  } catch (error: any) {
    yield put(actions.activateBallFail(error.toString()));
  }
}

export function* updateSurface(
  action: ReturnType<typeof actions.updateSurface>,
) {
  const b = action.payload;
  try {
    const response = yield call(
      putSurface,
      b.id,
      b.surface_mode,
      b.surface_grit,
      b.surface_grit2,
      b.surface_grit3,
      b.frames_since_last_surface,
    );
    yield put(actions.updateSurfaceSuccess(response));
    yield put(actions.listArsenalStart());
  } catch (error: any) {
    yield put(actions.updateSurfaceFail(error.message));
  }
}

export function* listBalls() {
  try {
    const balls = yield call(listArsenal);
    yield put(actions.listArsenalSuccess(balls.body));
  } catch (e) {
    yield put(actions.listArsenalFail((e as any).toString()));
  }
}

export function* setCurrentBall(
  action: ReturnType<typeof gameManagerActions.restoreGame>,
) {
  const arsonal: ReturnType<typeof selectArsenalBag> = yield select(
    selectArsenalBag,
  );
  const current = arsonal.find(
    b => b.id === action.payload?.lastDelivery?.ball?.id,
  );
  if (current) {
    yield put(currentBallActions.setCurrentBall(current));
  }
}
