import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.currentBall || initialState;

export const selectCurrentBall = createSelector([selectSlice], state => state);
export const selectCurrentBallDetails = createSelector(
  [selectSlice],
  state => state.current,
);

export const selectCurrentBallReport = createSelector([selectSlice], state => {
  return {
    id: state?.current?.id || 'UNKNOWN',
    countAtSurface: 0, // FIXME
  };
});
