/**
 *
 * BowlingBallCard
 *
 */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import TextureIcon from '@mui/icons-material/Texture';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { APIBallData } from 'app/pages/Arsenal/slice/types';
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Tooltip,
} from '@mui/material';
import { useArsenalSlice } from 'app/pages/Arsenal/slice';
import { useDispatch } from 'react-redux';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import { useNotificationsSlice } from '../Notifications/slice';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export const BallLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  '& .MuiLinearProgress-dashed': {
    backgroundColor: 'lightblue',
    backgroundImage: 'none',
    animation: 'none',
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: 'limegreen',
    backgroundImage: 'none',
    animation: 'none',
  },
}));

interface IBowlingBallOptions {
  actions?: JSX.Element[];
  noActions?: boolean;
}

export default function BowlingBallCard(
  props: APIBallData & IBowlingBallOptions,
) {
  const [expanded, setExpanded] = React.useState(false);

  const { actions } = useArsenalSlice();
  const { actions: notificationActions } = useNotificationsSlice();
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const MAX_GRIT = 5500;
  const highestGrit = [
    props.surface_grit,
    props.surface_grit2,
    props.surface_grit3,
  ]
    .filter(i => !isNaN(i))
    .sort()
    .reverse()[0];
  const grit = {
    value: (highestGrit / MAX_GRIT) * 100,
    valueBuffer:
      props.surface_mode === 'Polished'
        ? ((highestGrit + 2000) / MAX_GRIT) * 100
        : 0,
  };
  return (
    <Card elevation={3} sx={{ width: '100%' }}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     R
        //   </Avatar>
        // }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={props.ball_name}
        subheader={props.brand_name}
      />
      <CardMedia
        sx={{ padding: 0.5 }}
        component="img"
        height="194"
        image={`https://www.bowwwl.com/${props.thumbnail_image}`}
        alt="bowwwl thumbnail"
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} display={props.surface_grit ? 'block' : 'none'}>
            <BallLinearProgress
              {...grit}
              // // set this for polished
              // valueBuffer={0}
              // value={
              //   ([props.surface_grit, props.surface_grit2, props.surface_grit3]
              //     .filter(i => !isNaN(i))
              //     .sort()
              //     .reverse()[0] /
              //     5500) *
              //   100
              // }
              variant="buffer"
            />
            <Typography fontSize=".75em" textAlign="center">
              <strong>Grit</strong>{' '}
              {[props.surface_grit, props.surface_grit2, props.surface_grit3]
                .filter(i => !isNaN(i))
                .sort()
                .join('/')}
              <Typography component="span" variant="inherit" color="green">
                {props.surface_mode === 'Polished' && ' (Polished)'}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <BallLinearProgress
              value={((props.core_rg - 2.46) / 0.28) * 100}
              variant="determinate"
            />
            <Typography fontSize=".75em">
              <strong>RG</strong> {props.core_rg.toFixed(3)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <BallLinearProgress
              value={(props.core_diff / 0.06) * 100}
              variant="determinate"
            />
            <Typography fontSize=".75em">
              <strong>RG Dif</strong> {props.core_diff.toFixed(3)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <BallLinearProgress
              value={(props.core_int_diff / 0.03) * 100}
              variant="determinate"
            />
            <Typography fontSize=".75em">
              <strong>Int. Dif</strong> {props.core_int_diff.toFixed(3)}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {/* This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the
          mussels, if you like. */}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {!props.noActions && (
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={item => {
                dispatch(actions.deleteBall({ id: props.id }));
                dispatch(
                  notificationActions.toast({
                    component: (
                      <Box>
                        <Typography>
                          {props.ball_name} deleted
                          <Button>Undo</Button>
                        </Typography>
                      </Box>
                    ),
                    options: {
                      onClick: () =>
                        dispatch(actions.activateBall({ id: props.id })),
                      closeOnClick: true,
                    },
                  }),
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {!props.noActions && (
          <Tooltip title="Manage Surface">
            <Link href={`/arsenal/${props.id}/surface`}>
              <IconButton aria-label="surface">
                <TextureIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}
        {props.actions && props.actions}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6} display={props.weight ? 'block' : 'none'}>
              <Typography variant="subtitle2">Weight</Typography>
              <Typography variant="body2">{props.weight}</Typography>
            </Grid>
            <Grid item xs={6} display={props.layout ? 'block' : 'none'}>
              <Typography variant="subtitle2">Layout</Typography>
              <Typography variant="body2">{props.layout}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} display={props.surface_mode ? 'block' : 'none'}>
              <Typography variant="subtitle2">Surface</Typography>
              <Typography variant="body2">{props.surface_mode}</Typography>
            </Grid>
            <Grid item xs={6} display={props.surface_grit ? 'block' : 'none'}>
              <Typography variant="subtitle2">Grit</Typography>
              <Typography variant="body2">{props.surface_grit}</Typography>
            </Grid>

            <Grid item xs={6} display={props.frames_total ? 'block' : 'none'}>
              <Typography variant="subtitle2">Games</Typography>
              <Typography variant="body2">
                {(props.frames_total / 10).toFixed(1)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              display={props.frames_since_last_surface ? 'block' : 'none'}
            >
              <Typography variant="subtitle2">Games @ surface</Typography>
              <Typography variant="body2">
                {(props.frames_since_last_surface / 10).toFixed(1)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" mt={1}>
                Factory Finish
              </Typography>
              <Typography variant="body2">{props.factory_finish}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" mt={1}>
                Core Type
              </Typography>
              <Typography variant="body2">{props.core_type}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Core Name</Typography>
              <Typography variant="body2">{props.core_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" mt={1}>
                Coverstock Type
              </Typography>
              <Typography variant="body2">{props.coverstock_type}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Coverstock Name</Typography>
              <Typography variant="body2">{props.coverstock_name}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}
