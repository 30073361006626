import * as React from 'react';
import { ThemeOptions } from '@mui/material/styles';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';

interface IThemeOptions extends ThemeOptions {
  typography: {
    body3: React.CSSProperties; // Add your custom property here
  };
}

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to'];
  }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return (
    <RouterLink
      ref={ref}
      to={href}
      /* target="_top" this causes full reload */ {...other}
    />
  );
});

export const themeStyles: IThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#4a98e6',
    },
    secondary: {
      main: '#e6984a',
    },
    forwardOil: {
      // main: '#000',
      main: '#00f',
    },
    reverseOil: {
      // main: '#984ae6',
      main: '#f00',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  typography: {
    // This was a cool idea but it seem to change line breaking from body2...
    body3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
};
