/**
 *
 * PocketEntry
 *
 */
import {
  Box,
  Button,
  ButtonGroup,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pocketEntryActions, usePocketEntrySlice } from './slice';
import { selectGameManager } from '../GameManager/slice/selectors';
import { selectPocketEntry } from './slice/selectors';
import { useGameManagerSlice } from '../GameManager/slice';
import { Entry } from './slice/types';
import OutlinedDiv from '../OutlinedDiv';

interface Props {}

export function PocketEntry(props: Props) {
  const pocketEntrySelector = useSelector(selectPocketEntry);
  const entry = pocketEntrySelector.entry;

  const dispatch = useDispatch();
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newPocketEntry: Entry,
  ) => {
    dispatch(pocketEntryActions.setPocketEntry({ entry: newPocketEntry }));
  };

  usePocketEntrySlice();
  useGameManagerSlice();

  const game = useSelector(selectGameManager);
  const fullRack = game.fullRack;

  const valueUnsetIndicatorSx: Partial<SxProps> = {
    borderTop: 1,
    borderTopColor: entry ? 'green' : 'red',
  };

  return (
    <>
      <Box
        padding={0}
        overflow="hidden"
        sx={{ visibility: fullRack ? 'visible' : 'hidden' }}
      >
        <Typography fontSize=".75rem">Pocket Entry</Typography>
        <ToggleButtonGroup
          exclusive
          value={entry}
          onChange={handleChange}
          color="primary"
          sx={valueUnsetIndicatorSx}
          fullWidth={true}
          size="small"
        >
          <ToggleButton size="small" value="L miss">
            Left
          </ToggleButton>
          <ToggleButton size="small" value="high">
            high
          </ToggleButton>
          <ToggleButton size="small" value="flush">
            flush
          </ToggleButton>
          <ToggleButton size="small" value="light">
            light
          </ToggleButton>
          <ToggleButton value="R miss">Right</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
}
