/**
 *
 * SetRecap
 *
 */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectGameManager } from '../GameManager/slice/selectors';
import { AppActionsContext } from '../ResponsiveAppBar/AppActionsContext';
import { SetRecap } from '.';

export function CurrentSetRecap() {
  const games: any = [];
  const game = useSelector(selectGameManager);
  const { setAppActions } = React.useContext(AppActionsContext);
  React.useEffect(() => {
    setAppActions(<></>);
  }, [setAppActions]);

  for (let i = 0; i < game.completedGames.length; i++) {
    console.log('adding game', game.completedGames[i]);
    games.push(
      game.completedGames[i].map((game, i) => ({
        ...game,
        frameNo: i + 1,
        key: { i },
      })),
    );
  }

  games.push(
    game.frames.map((game, i) => ({
      ...game,
      frameNo: i + 1,
      key: { i },
    })),
  );

  return <SetRecap games={games} isCurrentGame />;
}
