/**
 *
 * CurrentBall
 *
 */
import * as React from 'react';
import { BallImage } from './BallImage';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useCurrentBallSlice } from './slice';
import { useSelector } from 'react-redux';
import { selectCurrentBallDetails } from './slice/selectors';
import { BallSelection } from './BallSelection';
import { useArsenalSlice } from 'app/pages/Arsenal/slice';
import { selectArsenalBag } from 'app/pages/Arsenal/slice/selectors';
interface Props {}

export function CurrentBall(props: Props) {
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [selectionOpen, setSelectionOpen] = React.useState(false);
  useCurrentBallSlice();
  useArsenalSlice();
  const ball = useSelector(selectCurrentBallDetails);

  return (
    <Box margin={1}>
      {/* <DetailView open={detailOpen} setOpen={setDetailOpen} /> */}
      <BallSelection open={selectionOpen} setOpen={setSelectionOpen} />
      <Card>
        <CardActionArea onClick={() => setSelectionOpen(true)}>
          {ball ? (
            <Box
              component="img"
              src={`https://www.bowwwl.com${ball?.thumbnail_image}`}
              alt="ball"
              m={0.5}
              sx={{ width: '90%' }}
            />
          ) : (
            <BallImage width="90%" title={'unknownball'} />
          )}

          <Typography
            gutterBottom
            fontSize="1rem"
            variant="h5"
            textAlign="center"
          >
            {!ball && <Typography>Select Ball</Typography>}
            {ball && ball.brand_name} {ball && ball.ball_name}
          </Typography>
          {/* <CardActions sx={{ padding: 0, margin: 0 }}>
          <IconButton
            onClick={() => setSelectionOpen(true)}
            size="small"
            sx={{ margin: 0, padding: 0 }}
          >
            <ChangeCircleIcon />
          </IconButton>
        </CardActions> */}
        </CardActionArea>
      </Card>
    </Box>
  );
}
