import { Box, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { BallLinearProgress } from '../BowlingBallCard';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const options: Partial<GridColDef> = {
  headerAlign: 'left',
  align: 'left',
  editable: false,
  minWidth: 130,
  flex: 1,
};

export const columns: GridColDef[] = [
  {
    ...options,
    field: 'ball_image',
    flex: 0,
    minWidth: 20,
    width: 80,
    type: 'string',
    valueFormatter: v => v.value && v.value.substring(0, 1).toUpperCase(),
    renderCell: (params: GridRenderCellParams<any, String>) => (
      <Box
        component="img"
        sx={{ width: '100%' }}
        alt="ball"
        src={`https://www.bowwwl.com${params.value}`}
      ></Box>
    ),
    headerName: '',
  },
  {
    ...options,
    field: 'ball_name',
    flex: 0,
    minWidth: 20,
    width: 80,
    type: 'string',
    // valueFormatter: v => v.value && v.value.substring(0, 1).toUpperCase(),
    // renderCell: (params: GridRenderCellParams<any, String>) => (
    //   <Box
    //     component="img"
    //     sx={{ width: '100%' }}
    //     alt="ball"
    //     src={`https://www.bowwwl.com${params.value}`}
    //   ></Box>
    // ),
    headerName: 'Name',
  },
  {
    ...options,
    field: 'surface_grit',
    flex: 0,
    minWidth: 20,
    width: 200,
    type: 'number',
    // valueFormatter: v => v.value && v.value.substring(0, 1).toUpperCase(),
    renderCell: (params: GridRenderCellParams<any, String>) => {
      const MAX_GRIT = 5500;
      const props = {
        surface_grit: params.row['surface_grit'],
        surface_grit2: params.row['surface_grit2'],
        surface_grit3: params.row['surface_grit3'],
        surface_mode: params.row['surface_mode'],
      };
      const highestGrit = [
        props.surface_grit,
        props.surface_grit2,
        props.surface_grit3,
      ]
        .filter(i => !isNaN(i))
        .sort()
        .reverse()[0];
      const grit = {
        value: (highestGrit / MAX_GRIT) * 100,
        valueBuffer:
          props.surface_mode === 'Polished'
            ? ((highestGrit + 2000) / MAX_GRIT) * 100
            : 0,
      };
      return (
        <Box height={10} width="100%">
          <BallLinearProgress {...grit} variant="buffer" />
        </Box>
      );
    },
    // {{params.row['surface_mode']}}
    headerName: 'Effective Grit',
  },
];
