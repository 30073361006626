import {
  Container,
  Grid,
  Stack,
  Typography,
  Link,
  List,
  ListItemText,
  Button,
} from '@mui/material';
import { Constants } from '../../../constants';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { ExternalLink } from 'utils/nav';
export function HomePage() {
  return (
    <>
      <Helmet>
        <title>Targeting</title>
        <meta name="description" content="Improve your bowling" />
      </Helmet>
      <Container>
        <Grid container rowSpacing={2} padding={2}>
          <Grid item xs={12} paddingLeft={10}>
            <Container>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Why?
              </Typography>
              <Typography
                fontStyle="italic"
                color="secondary"
                fontSize="2.4rem"
                variant="body1"
              >
                "I'll just throw it better."
              </Typography>
              <Typography
                sx={{ mb: 1.5 }}
                color="text.secondary"
                fontStyle="italic"
              >
                - Founder of {Constants.BrandName} and mediocre bowler
              </Typography>
              <Typography variant="body2">A losing strategy</Typography>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <article about="The game has changed">
              <Stack spacing={2}>
                <Typography variant="h4">The Game Has Changed</Typography>
                <Typography>
                  Bowling used to be about shot execution and accuracy with a
                  small emphasis on lane play. Not anymore. Vast changes in
                  equipment and oil patterns have shifted the skills required to
                  be successful. Today, you better be in the right part of the
                  lane, with the right ball and surface if you want to score.
                </Typography>
                <Typography>
                  I can't tell you how many times I've contemplated a ball
                  change or target move only to talk myself out of it because "I
                  didn't throw that shot great." Or how many times I've been
                  practicing and mistook bad or good ball motion for changes in
                  my mechanics.
                </Typography>
                <Typography component="div">
                  To be clear, shot mechanics <em>are</em> important. You should
                  work on your phsical game, but
                  <Typography
                    color="primary"
                    display={'inline'}
                    fontWeight={'bold'}
                  >
                    {' '}
                    don't neglect the mental game.
                  </Typography>
                </Typography>
              </Stack>
            </article>
          </Grid>
          <Grid item xs={12}>
            <article about="What's this all about">
              <Stack spacing={2}>
                <Typography variant="h4">What's this all about?</Typography>
                <Typography>
                  If you've landed here, it's either because I want your help
                  realizing this app that I've been designing in my head for
                  about 8 years. (Or you've stumbled here from a google search)
                </Typography>
                <Typography color="secondary" fontWeight={'bold'}>
                  This is a beta site to help me mock up components that will
                  deliver the best bowling app available.
                </Typography>
              </Stack>
            </article>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={3}>
              <Stack spacing={2}>
                <Typography variant="h5">Design goals</Typography>
                <Typography>
                  - Stream lined data entry and collect as much (or as little)
                  data as you want.
                </Typography>
                <Typography>- Mobile first</Typography>
                <Typography>- No native app to install</Typography>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h5">Influences</Typography>
                <List>
                  <ListItemText>
                    <ExternalLink href="https://www.kegeltrainingcenter.com/ktc-magazine/2014/6/7/understanding-3-point-targeting-with-quiet-eye-in-7-easy-steps">
                      Three point targeting
                    </ExternalLink>
                  </ListItemText>
                  <ListItemText>
                    <ExternalLink href="https://bowl.com/reading-the-lane">
                      Rule of 31
                    </ExternalLink>
                  </ListItemText>
                  <ListItemText>
                    <ExternalLink href="https://www.youtube.com/watch?v=rOOZdetR2Fs">
                      {' '}
                      Mo Pinel (ball motion)
                    </ExternalLink>
                  </ListItemText>
                  <ListItemText>
                    <ExternalLink href="https://bowlingknowledge.info/DYDS/">
                      {' '}
                      Joe Slowinski (international coach)
                    </ExternalLink>
                  </ListItemText>
                </List>
                {/* </Typography> */}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <article about="Links to recent changes">
              <Stack spacing={0.25}>
                <Typography variant="h4">Recent updates</Typography>
                <Typography>Navbar and app header</Typography>
                <Button
                  sx={{ textTransform: 'none', display: 'inline' }}
                  href="/pitch/"
                >
                  Pricing tier (ideas)
                </Button>
              </Stack>
            </article>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
