import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.arsenal || initialState;

export const selectArsenal = createSelector([selectSlice], state => state);

export const selectBallSearchResults = createSelector([selectSlice], state => {
  return state.ballSearchResults;
});

export const selectBallSearchValue = createSelector([selectSlice], state => {
  return state.search;
});

export const selectArsenalBag = createSelector([selectSlice], state => {
  return state.bag;
});

export const selectBallById = (id: string) => state =>
  selectArsenalBag(state).find(b => b.id === id);
