import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Container, Grid } from '@mui/material';

const steps = [
  {
    label: 'Targets',
    description: `Keep track of your target on each lane.`,
  },
  {
    label: 'Execution',
    description: 'Miss left? No problem - you should have room!',
  },
  {
    label: 'Pocket Entry',
    description: `How did the ball enter the pocket?`,
  },
  {
    label: 'Result',
    description: `They can't all strike. Or can they?`,
  },
  {
    label: 'Deck Exit',
    description: `This may be the most important piece of data. Do you pay attention?`,
  },
  {
    label: 'Pick the right ball',
    description: `Select the right piece. Keep track of individual ball performance.`,
  },
  {
    label: 'Even more data',
    description: `These things don't change as much, but they are there to help you archive the ball motion you're looking for.`,
  },
];

export default function TextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" p={2}>
      <Grid item xs={12} sm={8}>
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 2,
            bgcolor: 'background.default',
          }}
        >
          <Typography variant="h5" mb={1}>
            {steps[activeStep].label}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={8} md={6}>
        <Box
          component="img"
          src={`/images/chartstepper${activeStep + 1}.png`}
          width="100%"
        ></Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box sx={{ height: 100, width: '100%', p: 1 }}>
          <Typography
            variant="body1"
            color={theme.palette.secondary.main}
            textAlign="center"
          >
            {steps[activeStep].description}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} m={1}>
        <Box
          width="100%"
          boxShadow={8}
          sx={{
            p: 0.25,
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
}
