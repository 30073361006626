import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { PinFallControllerState } from './types';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectPinFallReport } from './selectors';

export const initialState: PinFallControllerState = {
  status: [
    ...Array(10)
      .fill({})
      .map((item, i) => ({
        pin: i + 1,
        active: true,
        up: false,
      })),
  ],
};

const slice = createSlice({
  name: 'pinFallController',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(
        gameManagerActions.commit,
        (state, action: PayloadAction<any>) => {
          const needFullRack =
            state.status.filter(pin => !pin.up).length === 10;
          if (needFullRack) {
            state.status = initialState.status;
          } else {
            const newState = state.status.map(pin => ({
              pin: pin.pin,
              active: pin.up,
              up: false,
            }));
            state.status = newState;
          }
        },
      )
      .addCase(
        gameManagerActions.newFrame,
        (state, action: PayloadAction<any>) => {
          return initialState;
          // return state;
        },
      )
      .addCase(
        gameManagerActions.newGame,
        (state, action: PayloadAction<any>) => {
          return initialState;
          // return state;
        },
      )
      .addCase(
        gameManagerActions.beginEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.save = state.status;
        },
      )
      .addCase(
        gameManagerActions.setEditDelivery,
        (
          state,
          action: PayloadAction<{
            pinStatus?: ReturnType<typeof selectPinFallReport>;
          }>,
        ) => {
          state.status = action.payload.pinStatus?.pinStatus as any;
        },
      )
      .addCase(
        gameManagerActions.finishEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.status = state.save || initialState.status;
        },
      )
      .addCase(
        gameManagerActions.restoreGame,
        (
          state,
          action: PayloadAction<{
            lastDelivery: {
              pinStatus?: ReturnType<typeof selectPinFallReport>;
            };
          }>,
        ) => {
          try {
            state.status = action.payload.lastDelivery.pinStatus
              ?.pinStatus as any;
            const needFullRack =
              state.status.filter(pin => !pin.up).length === 10;
            if (needFullRack) {
              state.status = initialState.status;
            } else {
              const newState = state.status.map(pin => ({
                pin: pin.pin,
                active: pin.up,
                up: false,
              }));
              state.status = newState;
            }
          } catch (e) {}
        },
      ),
  reducers: {
    togglePin(state, action: PayloadAction<{ pin: number }>) {
      const pin = state.status.find(i => i.pin === action.payload.pin);
      pin!.up = !pin?.up;
    },
    // report(
    //   state,
    //   action: PayloadAction<Pick<PinFallControllerState, 'report'>>,
    // ) {},
    // report(
    //   state,
    //   action: PayloadAction<{
    //     pinStatus: Pick<PinFallControllerState, 'status'>;
    //     count: number;
    //   }>,
    // ) {},
  },
});

export const { actions: pinFallControllerActions } = slice;

export const usePinFallControllerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePinFallControllerSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
