/**
 *
 * Pricing
 *
 */
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import PricingHero from 'app/components/PricingHero';
import * as React from 'react';
import { ExternalLink } from 'utils/nav';
import ChartStepper from './ChartStepper';

interface Props {}
const FlexContainer = styled('div')({
  display: 'inline',
});
export function PricingPage(props: Props) {
  const theme = useTheme();
  return (
    <>
      <Grid
        padding={1}
        container
        direction="column"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          xs={12}
          sm={8}
          md={6}
          lg={6}
          xl={5}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h4" mt={3}>
              Why this App?
            </Typography>
            <Typography variant="body2" mt={1}>
              There are dozens of excellent bowling apps for tracking pinfall,
              analyzing stats, viewing lane patterns or even calculating your
              speed and RPMs. Unfortunately, none of them come together in a
              cohesive, streamline way to help you better understand{' '}
              <Typography
                variant="body2"
                component="span"
                color={theme.palette.primary.main}
              >
                ball motion{' '}
              </Typography>
              and your{' '}
              <Typography
                variant="body2"
                component="span"
                color={theme.palette.primary.main}
              >
                performance{' '}
              </Typography>
              over time.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" mt={3}>
              The playing field
            </Typography>
          </Grid>
          <Grid item xs={3} pt={2}>
            <Box
              px={{ sm: 1, md: 2, lg: 5 }}
              component="img"
              src="/images/golf.gif"
              width="100%"
              // height="50vh"
              padding={1}
            ></Box>
          </Grid>
          <Grid
            item
            xs={9}
            padding={2}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2">
              Imagine a championship golf hole.
            </Typography>
            <Typography variant="body2" mt={1}>
              There's water to the left, sand bunkers flanking the fairway and
              the green is lightening fast with a treacherous slope left and to
              the back.
            </Typography>
            <Typography variant="body2" mt={1}>
              There are similar hazards on the bowling lane except that they are{' '}
              <Typography
                variant="body2"
                color="primary"
                component="span"
                fontWeight={1000}
              >
                invisible{' '}
              </Typography>
              - and - they change with every frame bowled!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Once you release the ball{' '}
              <Typography
                component="span"
                variant="body2"
                color="primary"
                fontWeight={1000}
              >
                you are an spectator!
              </Typography>
            </Typography>
            <Typography variant="body2" mt={1}>
              This application has several tools that will train your eye.
              Collect as much (or as little) data as you desire. Built with a
              singular purpose - Helping you
              <Typography
                variant="h5"
                color={theme.palette.secondary.main}
                padding={2}
              >
                See the Lane
              </Typography>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h4">Bag management</Typography>
            <Typography variant="body2" mt={1}>
              When you tee off, you might pull out the big stick - or you might
              use the modest 3-iron and completely take those bunkers out of
              play. You have a set of clubs that you draw from for each
              scenario.
            </Typography>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={3} p={0.5}>
                <Typography variant="body2" color="inherit" mt={1}>
                  A well built bowling bag should be similar. You need to
                  understand what ball gives you the highest success rate for
                  the current conditions.
                </Typography>
                <Typography variant="body2" mt={3}>
                  Sometimes you might need to turn your 7 iron into a 5 with an
                  abralon pad.
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Box
                  px={{ sm: 6, md: 3, lg: 6 }}
                  component="img"
                  src="/images/arsenal.png"
                  width={{ xs: '100%', md: '80%' }}
                  // height="50vh"
                  padding={1}
                ></Box>
              </Grid>
            </Grid>
            <Divider />
            <Typography variant="body2" mt={1}>
              To balance your bag and keep your equipment in top shape, we have
              a surface manager built in.
            </Typography>
            <Grid
              container
              direction="column"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={12} m={3}>
                <Box
                  width="100%"
                  height="auto"
                  // px={{ xs: 5, sm: 8, md: 16, lg: 20 }}
                  component="img"
                  src="/images/surface.png"
                  // height="50vh"
                ></Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Typography variant="body2" mt={1}>
              It's often difficult to understand the core differences between
              two balls so we make it easier to tell at a glance
            </Typography>
            <Box
              px={{ sm: 3, md: 6, lg: 5 }}
              component="img"
              src="/images/core1.png"
              width="100%"
              // height="50vh"
              padding={1}
            ></Box>
            <Box
              px={{ sm: 3, md: 6, lg: 5 }}
              component="img"
              src="/images/core2.png"
              width="100%"
              // height="50vh"
              padding={1}
            ></Box>
            <Typography variant="body2" mt={1}>
              These numbers seem similar but will give you dramatically
              different ball motion and forgiveness.
            </Typography>

            <Grid item xs={12}>
              <Typography variant="h4" mt={1}>
                Pattern visualizer
              </Typography>
              <Typography variant="body2" mt={1}>
                We modeled the bowling lane to it's <strong>exact</strong>{' '}
                specification. Then we built a virtual lane machine and a
                visualizer.
              </Typography>
              <Box
                component="img"
                src="/images/pattern2.png"
                width="80%"
                padding={1}
              ></Box>
              <Typography variant="body2" mt={1}>
                Drill into each Load of a pattern to gain insights you can't get
                from a static image.
              </Typography>
              <Typography variant="body2" mt={1}>
                Compare two patterns side by side.
              </Typography>
              <Typography
                variant="body2"
                mt={1}
                color={theme.palette.grey[700]}
              >
                Run break down simulations and anticipate the transition like
                never before.
              </Typography>
              <Typography variant="body2" mt={1}>
                You can even create your own patterns.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mt={1}>
                Targeting
              </Typography>
              <Typography variant="body2" mt={1}>
                The targeting module is used in many ways. A beginner will
                benefit from exploring and saving how they will shoot at spares.
                Intermediate bowlers will plan how to attack a particular
                pattern pre-competition. Advanced bowlers may replay their
                tournament after the pattern is reveled.
              </Typography>
              <Typography variant="body2" mt={1}>
                This is based on{' '}
                <Link
                  component={ExternalLink}
                  href="https://www.kegeltrainingcenter.com/ktc-magazine/2014/6/7/understanding-3-point-targeting-with-quiet-eye-in-7-easy-steps"
                  target="_blank"
                >
                  the 3-Point Targeting
                </Link>{' '}
                system. During competition, you'll track exacly where you were
                targetting lane to lane and frame to frame. Stay ahead of the
                transition!
              </Typography>
              <Box
                pt={3}
                px={{ sm: 2, md: 2, lg: 5 }}
                component="img"
                src="/images/target.png"
                width="100%"
                // height="50vh"
              ></Box>
              <Typography variant="body2" mt={1}>
                Gain valuable insights about the impacts of a 2 board drift or
                moving your target 3 feet down the lane. Know your launch angle
                and more.
              </Typography>
              <Grid container justifyContent="center" alignItems="center" p={3}>
                <Grid item xs={6}>
                  <Box
                    component="img"
                    src="/images/10pin.png"
                    width="80%"
                    // height="50vh"
                    padding={1}
                  ></Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    component="img"
                    src="/images/10pin2.png"
                    width="80%"
                    // height="50vh"
                    padding={1}
                  ></Box>
                </Grid>
              </Grid>

              <Typography variant="body2" color="inherit" noWrap>
                &nbsp;
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mt={1}>
                Charting
              </Typography>
              <Typography variant="body2" mt={1}>
                Many applications allow you to track your pinfall. That's
                helpful, but it's much more valuable to know how the ball went
                through the pins and exited the pin deck.
              </Typography>
              <Typography variant="body2" mt={1}>
                The charting module is fully customizable and allows you to
                start simple and layer in more and more advanced techniques to
                tweak your ball motion.
              </Typography>
              <Typography variant="body2" mt={1}>
                The interface was designed to be as optimal as possible enabling
                you to quickly record each shot. Visualize your goals for each
                ball, ace it, and then record. Start at the bottom and work up
                on the main section of the charter. The right column lets you
                think and document the things that change less often.
              </Typography>
              <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
              >
                <Grid item xs={12} m={3}>
                  <ChartStepper />
                </Grid>
              </Grid>
              <Typography variant="body2" mt={1}>
                Does lane 1 always hook more than lane 2 at your house? There's
                topographical and environmental reasons for that! Take notes in
                the app and be prepared.
              </Typography>
              <Typography variant="body2" color="inherit" noWrap>
                &nbsp;
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mt={1}>
                Advanced Analytics
              </Typography>
              <Typography variant="body2" mt={1}>
                You're observing and entering a lot of data. We'll show you
                today's performance and your performance over time, on different
                lane conditions, at different bowling centers.
              </Typography>
              <Grid container justifyContent="center" alignItems="center" p={3}>
                <Grid item xs={6}>
                  <Box
                    component="img"
                    src="/images/stats1.png"
                    width="100%"
                    // height="50vh"
                    padding={1}
                  ></Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    component="img"
                    src="/images/stats2.png"
                    width="100%"
                    // height="50vh"
                    padding={1}
                  ></Box>
                </Grid>
              </Grid>

              <Typography variant="body2" color="inherit" noWrap>
                &nbsp;
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PricingHero />
    </>
  );
}
