export async function postArsenal(
  ballId: string,
  weight: number,
  surfaceMode: string,
  framesAtSurface: number,
  totalFrames: number,
  grit: number,
  layout: string,
) {
  const response = await fetch(`${process.env.REACT_APP_API_URI}/arsenal/add`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    // credentials: 'include',
    method: 'post',
    body: JSON.stringify({
      ballId,
      weight,
      surfaceMode,
      framesAtSurface,
      grit,
      layout,
      totalFrames,
    }),
  });
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}

export async function listArsenal() {
  const response = await fetch(`${process.env.REACT_APP_API_URI}/arsenal`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    // credentials: 'include',
    method: 'get',
  });
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}

export async function patchBall(
  ballId: string,
  status: 'deleted' | 'active' | 'inactive',
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/arsenal/${ballId}/status`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
        'Content-Type': 'application/json',
      },
      method: 'put',
      body: JSON.stringify({
        status,
      }),
    },
  );
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}

export async function putSurface(
  ballId: string,
  surface_mode: string,
  surface_grit: number,
  surface_grit2: number,
  surface_grit3: number,
  frames_since_last_surface: number,
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/arsenal/${ballId}/surface`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
        'Content-Type': 'application/json',
      },
      method: 'put',
      body: JSON.stringify({
        surface_mode: surface_mode === '' ? undefined : surface_mode,
        surface_grit: surface_grit || undefined,
        surface_grit2: surface_grit2 || undefined,
        surface_grit3: surface_grit3 || undefined,
        frames_since_last_surface,
      }),
    },
  );
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}
