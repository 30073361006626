/**
 *
 * RecapsPage
 *
 */
import { Box, Stack } from '@mui/material';
import { CopyLink } from 'app/components/CopyLink';
import { AppActionsContext } from 'app/components/ResponsiveAppBar/AppActionsContext';
import { SetList } from 'app/components/SetList';
import { SetRecap } from 'app/components/SetRecap';
import { useSetSearchSlice } from 'app/components/SetSearch/slice';
import { selectSetSearch } from 'app/components/SetSearch/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useResolvedPath } from 'react-router-dom';

interface Props {}

export function RecapReview(props: Props) {
  const { actions } = useSetSearchSlice();
  const dispatch = useDispatch();
  const { setId } = useParams();
  const sets = useSelector(selectSetSearch);

  useSetSearchSlice();

  const { setAppActions } = React.useContext(AppActionsContext);

  React.useEffect(() => {
    setAppActions([
      <Box key="actions" flex={1} sx={{ px: 2 }}>
        <Stack direction="row" spacing={1}>
          <CopyLink />
        </Stack>
      </Box>,
    ]);
    dispatch(actions.getSetApiStart({ id: setId || '' }));
  }, [actions, dispatch, setAppActions, setId]);

  if (setId && setId.length && sets.currentSet?.games) {
    return <SetRecap games={sets.currentSet?.games} />;
  }

  return <p>Loading...</p>;
}
