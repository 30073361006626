import { Share } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { notificationsActions } from '../Notifications/slice';
import { useDispatch } from 'react-redux';

export const CopyLink = () => {
  const dispatch = useDispatch();
  return (
    <Button
      sx={{ zIndex: 100, fontSize: '.7em' }}
      variant="contained"
      size="small"
      fullWidth={true}
      onClick={async () => {
        try {
          // eslint-disable-next-line no-restricted-globals
          await navigator.clipboard.writeText(location.href);
        } catch (e) {
          dispatch(
            notificationsActions.toast({
              component: `Error saving target: ${(e as any).toString()}`,
              options: {
                type: 'error',
              },
            }),
          );
        }
      }}
      endIcon={<Share fontSize="small" />}
    >
      Copy Link
    </Button>
  );
};
