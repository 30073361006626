/**
 *
 * BallList
 *
 */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectArsenalBag } from '../Arsenal/slice/selectors';
import { BallTable } from 'app/components/BallTable';
import { useArsenalSlice } from '../Arsenal/slice';
import { Box } from '@mui/material';

interface Props {}

export function BallList(props: Props) {
  useArsenalSlice();
  const balls = useSelector(selectArsenalBag);
  return (
    <Box height="90vh">
      <BallTable rows={balls} />
    </Box>
  );
}
