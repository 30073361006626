/**
 *
 * AddBall
 *
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useArsenalSlice } from 'app/pages/Arsenal/slice';
import { useNavigate } from 'react-router-dom';

interface Props {
  newBall?: any;
}

export function AddBall(props: Props) {
  const { newBall } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const [grit, setGrit] = React.useState<number>(0);
  const [layout, setLayout] = React.useState<string>('');
  const [weight, setWeight] = React.useState('15');
  const [totalGames, setTotalGames] = React.useState(0.0);
  const [gamesAtSurface, setFramesAtSurface] = React.useState(0.0);
  const [surfaceMode, setSurfaceMode] = React.useState('Polished');

  const navigate = useNavigate();

  const handleChangeSurfaceMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string,
  ) => {
    setSurfaceMode(newMode);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleEventTypeChange = (event: SelectChangeEvent) => {
    setWeight(event.target.value);
  };

  const dispatch = useDispatch();
  const { actions } = useArsenalSlice();

  // React.useEffect(() => {
  //   if (bowlingSet.initializing) {
  //     setLane('');
  //     setLane('');
  //     setTarget('');
  //     setEvent('');
  //     setLaydown('');
  //     setEventType('appTesting');
  //     setAppActions(<></>);
  //   }
  // }, [bowlingSet.initializing, setAppActions]);

  if (!newBall) return <></>;

  return (
    <Box sx={{ maxWidth: 400 }} pt={2}>
      <Grid container>
        <Grid item xs={7} alignContent="center">
          <Typography variant="h3">{newBall.ball_name}</Typography>
          <Typography variant="h5">{newBall.brand_name}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Box
            component="img"
            sx={{ width: '100%' }}
            alt="ball"
            src={`https://www.bowwwl.com${newBall.ball_image}`}
          />
        </Grid>
      </Grid>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="weight">
          <StepLabel>Weight</StepLabel>
          <StepContent>
            <Select
              labelId="add-ball-select-weight"
              id="add-ball"
              value={weight}
              label="Event Type"
              onChange={handleEventTypeChange}
            >
              {[8, 9, 10, 11, 12, 13, 14, 15, 16].map(v => (
                <MenuItem value={v.toFixed(0)}>{v.toFixed(0)}</MenuItem>
              ))}
            </Select>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!weight}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="layout">
          <StepLabel>Layout</StepLabel>
          <StepContent>
            <TextField
              autoFocus
              label="Layout (optional)"
              placeholder="45x5x60"
              value={layout}
              onChange={e => setLayout(e.target.value)}
              autoComplete="off"
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="Surface">
          <StepLabel>Current Surface</StepLabel>
          <StepContent>
            <Stack direction={'column'} spacing={2}>
              <ToggleButtonGroup
                color="primary"
                value={surfaceMode}
                exclusive
                onChange={handleChangeSurfaceMode}
              >
                <ToggleButton size="small" value="Polished">
                  Polished
                </ToggleButton>
                <ToggleButton size="small" value="Sanded">
                  Sanded
                </ToggleButton>
              </ToggleButtonGroup>
              <TextField
                onFocus={event => {
                  event.target.select();
                }}
                autoFocus
                autoComplete="off"
                label="Grit"
                value={grit}
                onChange={e =>
                  setGrit(
                    isNaN(parseFloat(e.target.value))
                      ? 0
                      : parseFloat(e.target.value),
                  )
                }
                inputProps={{
                  inputMode: 'numeric',
                }}
              />
              <TextField
                onFocus={event => {
                  event.target.select();
                }}
                type="number"
                autoComplete="off"
                label="Approx total games thrown"
                value={totalGames}
                onChange={e => setTotalGames(parseFloat(e.target.value) || 0)}
                inputProps={{
                  inputMode: 'numeric',
                }}
              />
              <TextField
                onFocus={event => {
                  event.target.select();
                }}
                type="number"
                autoComplete="off"
                label="Approx games @ current surface"
                value={gamesAtSurface}
                onChange={e =>
                  setFramesAtSurface(parseFloat(e.target.value) || 0)
                }
                inputProps={{
                  inputMode: 'numeric',
                }}
              />
            </Stack>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {activeStep === 3 && (
        <Paper square elevation={0} sx={{ p: 3, pl: 0 }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(
                  actions.addBallToBag({
                    ballId: newBall.ball_id,
                    ballName: newBall.ball_name,
                    framesAtSurface: gamesAtSurface * 10,
                    grit,
                    layout,
                    surfaceMode,
                    weight,
                    totalFrames: totalGames * 10,
                    navigate,
                  }),
                );
              }}
            >
              Add ball to arsenal!
            </Button>
            <Button variant="outlined" onClick={handleReset}>
              Reset
            </Button>
          </Stack>
        </Paper>
      )}
    </Box>
  );
}
